<template>
  <div ref="authRef" class="auth">
    <button v-if="!user" class="btn btn-gradient btn_login h45" @click="login">
      {{ t('login') }} {{ t('via') }} Steam
    </button>
    <div v-else :class="{ open: showMenu }" class="user cselect" @click="showMenu = !showMenu">
      <button class="p0 f gap8" type="button">
        <img

            :src="user?.avatar_url ? getImageFromProxy(avatarUrl || '', '100:100') : 'https://ui-avatars.com/api/?name=' + user.persona_name"
            alt="user avatar"
            class="wh40 round user_avatar"
            loading="lazy"
        />
        <span class="user_name item ht hd">
          {{ user?.persona_name }}
        </span>
      </button>

      <div class="user_menu cselect_dropdown">
        <ul class="list cselect__list">
          <li class="user_name item" @click.stop>
            {{ user?.persona_name }}
          </li>
          <li>
            <button
                class="btn item"
                @click="logoutHandler"
            >
              {{ t('logout') }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useRuntimeConfig} from 'nuxt/app'
import {computed, onBeforeUnmount, onMounted} from 'vue'
import {useI18n} from 'vue-i18n'
import {useStore} from '~/store'
import {getImageFromProxy} from "~/utils/get-image-url";

const {t} = useI18n()

// use store
const store = useStore()
const user = computed(() => store.user)

const config = useRuntimeConfig()
const url = config.public.baseUrlV2
const showMenu = ref(false)
const authRef = ref(null)

const avatarUrl = computed(() => {
  if (user.value && user.value?.avatar_url) {
    const url = user.value?.avatar_url
    const index = url.lastIndexOf('.');
    if (index === -1) return url;

    const extension = url.substring(index);
    const baseUrl = url.substring(0, index);
    return `${baseUrl}_medium${extension}`
  }
  return null
})
const login = () => {
  return (document.location = url + '/auth/steam')
}

const logoutHandler = async () => {
  try {
    await logout()
    const session = useCookie('session')
    session.value = null
    store.user = null
  } catch (e) {
    console.log(e)
  }
}


const documentClick = (event: MouseEvent) => {
  let target = event.target as Element

  if (authRef.value && authRef.value !== target && !authRef.value.contains(target)) {
    showMenu.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', documentClick)
})

onBeforeUnmount(() => {
  document?.removeEventListener('click', documentClick)
})
</script>

<style lang="scss" scoped>
@import '/assets/scss/mixins.scss';

.btn_login {
  justify-content: center;
  white-space: nowrap;
  font-weight: 600;

  .hm {
    margin-left: 0.4rem;
  }

  @include _979 {
    margin-left: 1rem;
    height: 4.3rem;
  }
  @include _760 {
    margin-left: 0;
  }
}

.auth {
  display: flex;
  justify-content: center;
  @include _768 {
    .btn {
      width: 100%;
      height: 5rem;
    }
  }
}

.user {
  position: relative;

  &_menu {
    top: 120% !important;
    right: 0;
    left: unset;
    padding: 0;
    min-width: max(100%, 150px);

    .list {
      border: 1px solid #252930;
      border-radius: 1.2rem;

      .item {
        padding: 0.8rem 1.6rem;

        &.user_name {
          background: $darkLight;
          font-weight: 800;
          @include _768 {
            display: none;
          }
        }
      }
    }
  }
}
</style>
